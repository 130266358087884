/* *********************   Add new Litter   *********************/
.newTestimonyContainer {
  width: 90%;
  margin: auto;
  margin-top: 2vw;
  padding: 2rem;
  background-color: rgba(38, 43, 1, 0.497);
  border-radius: 1rem;
  border-color: rgb(248, 228, 50);
  border-style: solid;
  border-width: 0.5rem;
  display: block;
  color: rgb(254, 254, 254);
}
.newTestimonyContainer label {
  min-width: 9rem;
  padding: 0.25rem 1rem;
  margin: 0.5rem;
  border-radius: 0.5rem;
  color: black;
  text-align: center;
  background-color: rgba(239, 187, 55, 0.926);
}

.newTestimonyContainer input {
  color: black;
  border-radius: 0.2rem;
  padding: 0.5rem, 1rem;
}
.newTestimonyContainer textarea {
  color: black;
  width: 45%;
  min-width: 25rem;
  height: 10rem;
  border-radius: 0.3rem;
  margin: 0.5rem;
}
.newTestimonyContainer button {
  background-color: rgba(39, 78, 2, 0.973);
  border-color: rgb(253, 253, 63);
  border-width: 0.25rem;
  font-size: large;
  font-weight: bold;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
}
