/* ******************   Display each Litter   *********************/
.litterContainer {
  width: 90%;
  margin: auto;
  padding: 2rem;
  background-color: rgba(38, 43, 1, 0.497);
  border-radius: 1rem;
  border-color: rgb(243, 211, 49);
  border-style: solid;
  border-width: 0.5rem;
  display: grid;
  color: rgb(254, 254, 254);
}
@media screen {
  .litterContainer {
    grid-template-columns: repeat(3, 1fr);
  }
}
/* ****************** Delete Option - Litter ******************/
.deleteContainer {
  width: 90%;
  text-align: right;
  margin: auto;
  margin-top: 0rem;
}
.deleteContainer button {
  background-color: rgba(240, 45, 6, 0.976);
  border-color: rgb(235, 242, 37);
  border-width: 0.25rem;
  font-size: 1.85rem;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.965);
  padding: 0.5rem 1rem;
  border-radius: 1rem;
}
/* ********************   Litter Picture   *********************/
.pictureContainer {
  width: 90%;
  margin: auto;
  border-radius: 15%;
}
.pictureContainer img {
  width: 100%;
}

/* ********************** Litter Info *********************/
.litterInfo {
  width: 90%;
  margin: auto;
  margin-top: 0rem;
}
.litterInfo label {
  min-width: 8rem;
  padding: 0.25rem 1rem;
  margin: 0.5rem 0rem;
  border-radius: 0.5rem;
  color: black;
  text-align: center;
  background-color: rgba(255, 227, 14, 0.943);
}
.litterInfo input {
  color: rgb(6, 6, 6);
  background-color: rgb(161, 245, 237);
  border-radius: 0.5rem;
  padding: auto, 1rem;
}

/* ****************** Edit Options - litters ******************/
.edittingOptions {
  width: 90%;
  margin: auto;
  margin-top: 0rem;
}

.edittingOptions label {
  min-width: 8rem;
  padding: 0.25rem 1rem;
  margin: 0.5rem 0rem;
  border-radius: 0.5rem;
  color: rgb(37, 4, 1);
  text-align: center;
  background-color: rgba(244, 173, 30, 0.989);
}
.edittingOptions input {
  color: black;
  border-radius: 0.2rem;
  padding: 0.5rem, 1rem;
}
.edittingOptions button {
  background-color: rgb(190, 76, 5);
  border-color: rgb(126, 251, 2);
  border-width: 0.25rem;
  font-weight: bold;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
}
.edittingOptions select {
  color: black;
  border-radius: 0.2rem;
  padding: 0.5rem, 1rem;
  margin: 0.5rem 0rem;
  background-color: rgb(184, 251, 68);
}
