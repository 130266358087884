@import "../../theme.css";

.breedInfoPage {
  position: absolute;
  height: 100%;
  width: 100%;
}

.backgroundLayer {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-image: var(--bg-image2);
  width: 100%;
  background-size: contain;
  background-attachment: fixed;
  background-size: cover;
}

.topPictureContainer {
  width: 100%;
  margin-top: 1rem;
}
.topPicture {
  width: 50%;
  height: auto;
  border-style: double;
  align-self: center;
}

.sectionHeader {
  margin: 20px;
}

.bengalsTextContainer {
  background-color: #a87d48f5;
  font-size: var(--generic-text-size);
  color: white;
  font-family: var(--generic-website-text);
  text-align: justify;
  margin-bottom: 2vw;
}

.bengalsIntroPic {
  margin-left: 20px;
  margin-right: 20px;
}

.breedInfoTxt {
  /*position: absolute;*/
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: var(--header-font-size);
  color: white;
  font-family: var(--header-text-font);
}

/*.bottomContainer img {
  display: flex;
  width: 50%;
  margin: 10px 10px 30px 30px;
}*/

p.text {
  margin: 20px;
}

/*********************************************************************************/

.backgroundLayer Img {
  max-width: 100%;
  height: auto;
}

.containerBox {
  /*WebNaveHeader color: #412804*/
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-self: center;
  text-align: left;
  width: 85vw;
  position: relative;
  background-color: #a87d48f5; /* rgba(78, 58, 36);/*, 0.99); /*#4E3A24;*/
  color: white;
  font-family: var(--generic-website-text);
  font-size: var(--generic-text-size);
  margin-top: 2vw;
  margin-bottom: 60px;
  padding: 10px 10px 10px 10px;
  border-style: solid;
  border-color: var(--dark-secondary-color); /* rgba(255, 230, 207, 0.94);*/
  border-width: 10px;
  border-radius: 20px;
}

.containerRowBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  width: relative;
  max-width: 80vw;
  position: relative;
  /*right: 1rem;*/
  background-color: var(--dark-secondary-color);/*#a87d48f5; /*#4E3A24;*/
  color: white;
  font-family: var(--generic-website-text);
  font-size: var(--generic-text-size);
  margin-top: 2vw;
  margin-left: 5vw;
  margin-right: 5vw;
  margin-bottom: 60px;
  border-style: double;
  border-color: var(--dark-primary-color);
  border-width: 10px;
  border-radius: 20px;
}

.containerRowBox3 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  align-self: center;
  width: relative;
  max-width: 60vw;
  position: relative;
  /*right: 1rem;*/
  background-color: var(--dark-secondary-color);/*#a87d48f5; /*#4E3A24;*/
  color: white;
  font-family: var(--generic-website-text);
  font-size: var(--generic-text-size);
  margin-top: 2vw;
  margin-left: 5vw;
  margin-right: 5vw;
  margin-bottom: 60px;
  border-style: double;
  border-color: var(--dark-primary-color);
  border-width: 10px;
  border-radius: 20px;
}

.containerRowBox4 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  width: relative;
  max-width: 80vw;
  position: relative;
  /*right: 1rem;*/
  background-color: var(--dark-primary-color);/*#a87d48f5; /*#4E3A24;*/
  color: white;
  font-family: var(--generic-website-text);
  font-size: var(--generic-text-size);
  margin-top: 2vw;
  margin-left: 5vw;
  margin-right: 5vw;
  margin-bottom: 60px;
  border-style: double;
  border-color: var(--dark-secondary-color);
  border-width: 10px;
  border-radius: 20px;
}


.containerRow1 {
  margin: 10px 10px 10px 10px;
  background-color: #a87d48f5; /* #4E3A24;*/
  flex: 1;
  width: 45vw;
  align-self: center;
  /*height: auto;*/
  position: relative;
  border-radius: 10px;
  /*justify-content: center;*/
}

.containerRow3 {
  margin: 10px 10px 10px 10px;
  background-color: #a87d48f5; /* #4E3A24;*/
  flex: 1;
  width: 45vw;
  align-self: center;
  /*height: auto;*/
  position: relative;
  border-radius: 10px;
  align-content: center;
  /*justify-content: center;*/
}

.containerRow2 {
  margin: 5px 5px 5px 5px;
  background-color: #ffe3c8;
  flex: 1;
  width: 30vw;
  height: auto;
  position: relative;
  justify-content: center;
  padding-left: auto;
  padding-bottom: auto;
  text-align: center;
  align-content: center;
  align-self: center;
  color: black;
  border-radius: 20px;
  border-style: solid;
  border-color: #4e3a24;
  border-width: 10px;
  border-radius: 20px;
}

.imgContainer {
  max-width: 100%;
  height: auto;
  border-style: double;
  border-color: var(--dark-primary-color);
  border-width: 10px;
  border-radius: 20px;
  align-self: center;
  /*position: relative;*/
}

.imgContainer2 {
  max-width: 100%;
  height: auto;
  align-self: center;
  border-style: double;
  border-color: var(--dark-secondary-color);
  border-width: 10px;
  border-radius: 20px;
 
}

.bold {
  font: bold;
  font-size: 3vw;
  font-family: var(--header-text-font);
  width: auto;
  justify-content: center;
  text-align: center;
  align-self: center;
  padding: 10px 10px 10px 10px;
}

.underline {
  text-decoration: underline;
  font: italic;
  /*font: bold;*/
}

.textLeftBold {
  font: var(--generic-text-property);
  text-align: left;
  margin-left: 1rem;
  width: 30vw;
}

.containerRowBox2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  width: 90vw;
  /*right: 1rem;*/
  background-color: var(--dark-secondary-color);
  color: white;
  font-family: var(--header-text-font);
  font-size: var(--header-font-size);
  margin-top: 1rem;
  margin-left: 5rem;
  margin-right: 5rem;
  margin-bottom: 60px;
}

.containerBottom {
  display: flex;
  flex-direction: row;
  width: 100vw;
  justify-content: left;
  text-align: left;
  /*right: 1rem;*/
  background-color: rgba(78, 58, 36, 0.5);
  color: white;
  font-family: var(--generic-website-text);
  font-size: var(--generic-text-size);
  margin-top: 5vw;
  /*margin-left: 0;
  margin-right: 0;
  /*margin-bottom: 60px;*/
}

.funFactList {
  text-align: left;
}

.textContainer {
  text-align: center;
  margin-left: 1vw;
  margin-right: 1vw;
  font-family: var(--generic-website-text);
}

.containerRowTransparent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  width: relative;
  position: relative;
  /*right: 1rem;*/
  background-color: transparent;
  color: white;
  font-family: var(--generic-website-text);
  font-size: var(--generic-text-size);
  margin-top: 2vw;
  margin-left: 2vw;
  margin-right: 2vw;
  margin-bottom: 60px;
}

.text {
  width: auto;
  position: relative;
  text-align: left;
  font-family: var(--generic-website-text);
}

/************************media screen when shrinking *************************************/

@media only screen and (max-width: 1000px) {

  .containerRowBox {
    flex-direction: column;
    align-content: center;
  }

  .containerRowBox3{
    flex-direction: column;
    align-content: center;
    width:auto;
  }

  .containerRowBox4{
    flex-direction: column;
    align-content: center;
    background-color: transparent;
    border-color: var(--dark-primary-color);
  }

  .containerRow1 {
    width: 75vw;
    background-color: transparent;
  }

  .containerRow2 {
    /*display:contents;*/
    align-self: center;
    width: 78vw;
    align-content: center;
    border-style: solid;
    border-color: var(--dark-primary-color);
    border-radius: 20px;
    border-width: 10px 10px 10px 10px;
    /*flex-direction: row;*/
    /*width: 80vw;*/
  }

  .imgContainer2 {
    width: 70%;
    border-color: var(--dark-primary-color);
    margin-bottom: 2vw;
  }

}

/******************accordion*************************/
.accordion .contentBx .label {
  background: rgba(161, 89, 21, 0.269);
  cursor: pointer;
  padding: 10px;
}

/*Not working....................*/
.accordion .contentBx .label ::before {
  content: '+';
  position: absolute;
}

.accordion .contentBx .content {
  position: relative;
  background: #fff;
  height: 0;
  overflow: hidden;
  transition: 0.5s;

}

.accordion .contentBx.active .content {
  height: 150px;
  padding: 10px;
}


/***********Accordion*********************/