@import "../../theme.css";

@font-face {
  font-family: inika;
  src: url("../../fonts/Inika-Regular.ttf");
}
@font-face {
  font-family: lancelot;
  src: url("../../fonts/Lancelot-Regular.ttf");
}

/* >>>>>>>>>>>>>>>>>>    Testimonials' Container    <<<<<<<<<<<<<<<<<<*/
.testimonialsContainer {
  position: relative;
  width: 100%;
  margin: auto;
  margin-top: 4rem;
}
/* >>>>>>>>>>>>>>>>>>    Testimonials' Title   <<<<<<<<<<<<<<<<<<*/
.testimonialTitle {
  width: 70%;
  max-width: 500px;
  margin: 2rem auto;
  color: var(--light-primary-color);
  margin-top: 5vw;
  font-size: xx-large;
  font-weight: bolder;
  font-family: inika, lancelot;
  text-align: center;
  text-shadow: 0.15vw 0.15vw rgba(197, 117, 6, 0.89);
  background-color: rgba(55, 29, 2, 0.835);
  opacity: 0.9;
  border-style: outset;
  border-color: var(--light-primary-color);
  border-width: 5px;
  border-radius: 20px;
}
/* >>>>>>>>>>>>>>>>>>    Testimonies' Container    <<<<<<<<<<<<<<<<<<*/
.testimoniesContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* >>>>>>>>>>>>>>>>>>    Testimony's Container   <<<<<<<<<<<<<<<<<<*/
.testimoniesRow {
  width: fit-content;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  overflow-x: scroll;
  scroll-behavior: smooth;
  align-items: stretch;
  position: relative;
  gap: 3rem;
}

/* >>>>>>>>>>>>>>>>>>    Testimony's Container   <<<<<<<<<<<<<<<<<<*/
.testimonyContainer {
  margin: 2rem 0rem;
  width: 20rem;
  min-width: 350px;
  position: relative;
  display: inline-block;
  white-space: normal;
}

/* >>>>>>>>>>>>>>>>>>    Testimony's Image   <<<<<<<<<<<<<<<<<<*/
.testimonyContainer img {
  width: 100%;
  height: 400px;
  object-fit: cover;
  margin: auto;
  border-top-right-radius: 50px;
  border-top-left-radius: 50px;
  border-style: inset;
  border-color: var(--light-primary-color);
  border-width: 5px;
  border-top-right-radius: 55px;
  border-bottom: none;
}
/* >>>>>>>>>>>>>>>>>>    Testimony's Text   <<<<<<<<<<<<<<<<<<*/
.testimonyText {
  width: 100%;
  background-color: rgba(0, 2, 2, 0.744);
  color: rgb(255, 250, 250);
  padding: 0.25rem 2rem;
  padding-bottom: 1rem;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  border-style: inset;
  border-color: var(--light-primary-color);
  border-width: 5px;
  border-radius: 55px;
  border-top: none;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  position: relative;
  margin: auto;
  justify-content: center;
}
.testimonyContainer h1 {
  text-align: center;
  font-weight: bold;
  font-size: large;
  font-family: "Times New Roman", Times, serif;
}
.testimonyContainer p {
  width: 100%;
  font-size: normal;
  font-weight: lighter;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.mdChevronLeft {
  background-color: var(--light-primary-color); /* white;*/
  left: 0px;
  border-radius: 1rem;
  position: absolute;
  opacity: 0.5;
  cursor: pointer;
  z-index: 10;
  display: block;
}

.mdChevronRight {
  background-color: white;
  right: 0px;
  border-radius: 1rem;
  position: absolute;
  opacity: 0.5;
  cursor: pointer;
  z-index: 10;
  display: block;
}
