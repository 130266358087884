/* *********************   Add new Parent   *********************/
.newParentContainer {
  width: 90%;
  margin: auto;
  margin-top: 2vw;
  padding: 2rem;
  background-color: rgba(31, 36, 178, 0.227);
  border-radius: 1rem;
  border-color: rgb(9, 239, 247);
  border-style: solid;
  border-width: 0.5rem;
  display: block;
  color: rgb(254, 254, 254);
}
.newParentContainer label {
  min-width: 8rem;
  padding: 0.25rem 1rem;
  margin: 0.5rem 0rem;
  border-radius: 0.5rem;
  color: black;
  text-align: center;
  background-color: rgba(255, 227, 14, 0.943);
}
.newParentContainer input {
  color: black;
  border-radius: 0.2rem;
  padding: 0.5rem, 1rem;
}
.newParentContainer button {
  background-color: rgba(29, 154, 8, 0.694);
  border-color: rgb(253, 243, 63);
  border-width: 0.25rem;
  font-size: large;
  font-weight: bold;
  color: rgb(255, 255, 255);
  padding: 0.5rem 1rem;
  border-radius: 1rem;
}

.newParentContainer legend {
  max-width: fit-content;
  border-style: none;
  font-weight: bold;
  display: flex;
  color: white;
  border-radius: 0.5rem;
  font-size: 1.5rem;
}

.newParentContainer fieldset {
  max-width: fit-content;
  margin: 0.5rem 0rem;
  border-width: 0.5rem;
  border-radius: 1rem;
  font-weight: bold;
  padding: 1rem;
  display: block;
  background-color: rgba(242, 164, 20, 0.291);
  font-size: 1.5rem;
}

.newParentContainer fieldset label {
  border-color: rgb(225, 255, 0);
  background-color: rgb(174, 250, 41);
  border-width: 0.25rem;
  padding: 0;
  font-size: normal;
}
