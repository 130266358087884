/* Email Form for Reserving A Kitten */
@import "../../theme.css";

.formContainer {
  width: 90vw;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  font-size: 1rem;
  background: #4E3A24;
  color: white;
  border-radius: 15px;
}

.formHeader {
  font-family: var(--header-text-font);
  font-size: var(--header-font-size);
}

.formTextInputs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 2;
}

.formKittenSelect {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
  flex-wrap: wrap;
  padding: 1rem;
}

.availableKittenRadioOption {
  display: flex;
  flex-direction: row;
  padding: 0.5rem;
}

.availableKittenRadioOptionInner {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  margin: 0.5rem;
}

#userName, #userEmail {
  width: 100%;
  height: 4.5rem;
  padding: 1rem;
  outline: none;
  border-radius: 5px;
  border: 1px solid rgb(220, 220, 220);
}

#userMessage {
  width: 100%;
  height: 9rem;
  padding: 1rem;
  outline: none;
  border-radius: 5px;
  border: 1px solid rgb(220, 220, 220);
}

#userName:focus, #userEmail:focus, #userMessage:focus {
  border: 2px solid rgba(168, 125, 72, 0.96);
}

#formLabel {
  font-size: 1.5rem;
  margin-top: 1rem;
}

#submitButton {
  border-radius: 5px;
  border: 1px solid rgb(220, 220, 220);
  margin-right: 7rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
  cursor: pointer;
  background: white;
  color: rgb(168, 125, 72);
  box-shadow: 0px 0px 2px 2px rgb(0,0,0);
  border: none;
  font-size: 1.7rem;
  font-weight: bold;
}

#submitButton:hover {
  background: rgb(168, 125, 72);
  color: white;
  transition: 0.7s;
}