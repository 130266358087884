@import "../../theme.css";

/* >>>>>>>>>>>>>>>>>>>> Homepage Container <<<<<<<<<<<<<<<<<<<<<<<<<<< */
.homePageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: conic-gradient(
    rgb(64, 29, 2),
    rgb(255, 174, 0),
    rgb(64, 29, 2)
  );
  background-image: var(--bg-image2);
  background-size: cover;
  background-attachment: fixed;
}

/* >>>>>>>>>>>>>>>>>>>> Homepage Header Image <<<<<<<<<<<<<<<<<<<<<<<<<<< */
.homeHeader {
  width: 100%;
  height: auto;
  position: relative;
  background-color: rgba(28, 14, 1, 0.611);
  border-style: double;
  border-color: var(--dark-primary-color);
  border-width: 0 0 10px 0;
}
.homeHeader Img {
  width: 100%;
  height: 100%;
}

/* >>>>>>>>>>>>>>>>>>>> Mission Statement  <<<<<<<<<<<<<<<<<<<<<<<<<<< */
.missionStatement {
  width: 100%;
  height: auto;
  position: relative;
  /*margin: 1rem;
  /*margin-top: 3vw;*/
  padding-top: 5rem;
  padding-bottom: 2rem;
  background-color: var(--dark-primary-color);
  /*opacity: var(--dark-bg-opacity);*/
  color: rgb(255, 255, 255);
  border-width: 0px 0px 5px 0px;
  border-bottom: #e2b78e;
  border-style: solid;
  /*border-style: solid;
  border-width: 5px;
  border-radius: 20px;
  border-color: #e2b78e;*/
  /*border-radius: var(--border-radius);
  border-width: var(--border-width);
  border-color: var(--light-primary-color);*/
  /* bottom: 0.2vw;
  left: 0.2vw; */
}

.missionOwnersName {
  text-align: right;
}

.missionStatementText {
  font-family: var(--generic-website-text);
  font-size: var(--generic-text-size);
  width: 90%;
  margin: auto;
  text-align: center;
}
/* >>>>>>>>>>>>>>>>>>>> Owner's Imageand her Cat <<<<<<<<<<<<<<<<<<<<<<<<<<< */
.ownerPicture {
  width: 80%;
  margin: 2rem auto;
}
.ownerPicture img {
  width: 100%;
  margin-top: 3vw;
  margin-bottom: 3vw;
  /*border-color: var(--light-primary-color);
  border-width: var(--border-width);
  border-radius: var(--border-radius);*/
  border-style:inset;
  border-color: var(--dark-primary-color);
  border-width: 20px;
}

/* >>>>>>>>>>>>>>>>>>    Past Litters' Title   <<<<<<<<<<<<<<<<<<*/
.pastLittersTitle {
  width: 70%;
  max-width: 500px;
  margin: auto;
  color: var(--light-primary-color);
  margin-top: 5vw;
  font-size: xx-large;
  font-weight: bolder;
  font-family: inika, lancelot;
  text-align: center;
  text-shadow: 0.15vw 0.15vw rgba(197, 117, 6, 0.89);
  background-color: rgba(55, 29, 2, 0.835);
  opacity: 0.9;
  border-style: outset;
  border-color: var(--light-primary-color);
  border-width: 5px;
  border-radius: 20px;
}

/* >>>>>>>>>>>>>>>>>>>>  Past Litters    <<<<<<<<<<<<<<<<<<<<<<<<<<< */
.pastLitters {
  width: 90%;
  margin: 2rem auto;
  display: grid;
  gap: 1rem;
}
@media screen {
  .pastLitters {
    grid-template-columns: repeat(2, 1fr);
  }
}
.pastLitters img {
  width: 90%;
  margin: auto;
  margin-bottom: 3vw;
  /*border-color: var(--light-primary-color);
  border-width: var(--border-width);*/
  border-style: solid;
  border-width: 5px;
  border-radius: 20px;
  border-color: #4e3a24;
}
