@import "../../theme.css";
/* >>>>>>>>>>>> Business Certificate Container <<<<<<<<<<<<<<<<*/
.businessCertificateContainer {
  position: relative;
  width: 90%;
  max-width: 900px;
  margin: auto;
  border-radius: 2rem;
  position: relative;
  /* margin-top: 3vw; */
  background-color: var(--dark-primary-color);
  opacity: var(--dark-bg-opacity);
  color: rgb(255, 255, 255);
  border-style: solid;
  border-width: 5px;
  border-radius: 20px;
  border-color: #e2b78e;
  font-family: var(--generic-website-text);
  font-size: var(--generic-text-size);
}

/* >>>>>>>>>>>>>>>>>> Certificatio Title  <<<<<<<<<<<<<<<<<<<<<*/
.bcTitle {
  display: flex;
  width: fit-content;
  align-items: center;
  text-justify: center;
  text-align: center;
  margin: auto;
}

/* >>>>>>>>>>>>>>>>>> KingBengals' Logo <<<<<<<<<<<<<<<<<<<<<*/
.kbLogoContainer {
  width: 150px;
}
.kbLogoContainer img {
  width: 100%;
  margin: auto;
}

/* >>>>>>>>>>>> Business Certificate Statement <<<<<<<<<<<<<<<<*/
.businessCertificateText {
  width: 90%;
  margin: auto;
  /*padding: 1rem;*/
  color: rgb(254, 254, 254);
  text-align: left;
  font-size: large;
}
/* statement link */
.bcLink {
  color: rgba(41, 41, 187, 0.85);
}

/* >>>>>>>>>>>> Tica's Certificate <<<<<<<<<<<<<<<<*/
.ticaCertificate {
  width: 90%;
  max-width: 300px;
  margin: auto;
  margin-bottom: 3rem;
}
.ticaCertificate img {
  width: 100%;
  border-radius: 5.5rem;
  background-color: rgba(237, 241, 252, 0);
}
