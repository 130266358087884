.modalBackground {
  width: 100%;
  height: 100%;
  background-color: rgba(200, 200, 200, 0.756);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50;
  top: 0;
  left: 0;
}

.modalContainer {
  margin: auto;
  margin-top: 1vw;
  max-width: 600px;
  width: 97%;
  min-width: 290px;
  max-height: 98%;
  min-height: fit-content;
  overflow-y: auto;
  color: black;
  border-radius: 12px;
  background-color: #a87d48;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 15px;
  z-index: 100;
}

.modalContainer::-webkit-scrollbar {
  width: 10px;
}

.modalContainer::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.modalContainer::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

.modalContainer::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.title {
  width: 87%;
  height: fit-content;
  font-family: var(--font-inika);
  font-size: var(--header-font-size);
  display: inline-block;
  background-color: #e2b78e;
  text-align: center;
  margin-top: -30px;
  margin-left: 5%;
  line-height: 0.8;
  border-radius: 10px;
}

.gridLitterandParents {
  display: grid;
  grid-template-columns: 1fr 0.4fr 0.4fr;
  grid-template-rows: 1fr;
  width: 80%;
  gap: 1vw;
  margin-top: 1vw;
  margin-left: 10%;
}

.litterImg {
  width: 100%;
  height: 100%;
}

.litterImg img {
  width: 100%;
  object-fit: cover;
  border-style: solid;
  border-width: 5px;
  border-color: #e2b78e;
  border-radius: 10px;
}

.litterParent {
  padding-top: 12px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.litterParentPicture {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 90%;
  height: 70%;
  border-radius: 50%;
  border-style: solid;
  border-width: 5px;
  border-color: #e2b78e;
}

.litterParentPicture img {
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
}

.momAndDadText {
  font-family: var(--header-text-font);
  text-align: center;
  font-size: var(--large-header-size);
  word-spacing: 1.2em;
}

.kittensContainer {
  margin-top: 5px;
  width: 95%;
  max-width: 95%;
  margin: auto;
  height: fit-content;
  display: flex;
  overflow-y: auto;
  gap: 12px;
  /* flex-direction: column; */
}

.kittenContainer {
  margin-top: 5px;
  width: 25%;
  min-width: 110px;
  height: fit-content;
  display: grid;
  gap: 12px;
}

.kittenPicture {
  height: 100px;
  display: flex;
  border-color: #e2b78e;
  border-style: solid;
  border-width: 5px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.kittenPicture img {
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
}

.kittenName {
  background-color: #edca9f3b;
  border-color: #eec197;
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
  text-align: center;
  font-weight: bold;
}

.kittenInfo {
  background-color: #e2b78e;
  font-size: 14px;
  height: fit-content;
  margin-top: -5px;
  line-height: 1;
  padding-top: 8px;
  padding-left: 5px;
  border-radius: 10px;
}

.closeBtn {
  display: flex;
  justify-content: flex-end;
}

.closeBtn button {
  background-color: white;
  width: 20px;
  border-style: solid;
  border-color: black;
  border-width: 1px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
}
