@import "../../theme.css";

.reserveAKittenPage {
  background-color: #a87d48f5;
}

.backgroundLayer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: conic-gradient(
    rgb(64, 29, 2),
    rgb(255, 174, 0),
    rgb(64, 29, 2)
  );
  background-image: var(--bg-image2);
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
}

.headerContainer {
  background-image: url("../../images/ReserveAKittenHeader.png");
  background-size: cover;
  width: 100%;
  height: fit-content;
}

.headerContainer img {
  width: 100%;
  height: 100%;
}

.headerContainerReserve {
  text-align: center;
  background-color: #87633d;
  color: white;
  font-family: var(--header-text-font);
  font-size: var(--header-font-size);
  margin-bottom: 70px;
  margin-top: 70px;
  margin-left: 20%;
  margin-right: 20%;
  padding: 10px 50px 10px 50px;
  border-style: solid;
  border-color: #4E3A24;
  border-width: 5px;
  border-radius: 20px;
}

.headerReserveTitle {
  font-family: var(--header-text-font);
  font-size: var(--largest-header-size);
}

@media only screen and (max-width: 690px) {
  .headerContainerReserve {
    width: 80%;
    margin-left: 10%;
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media only screen and (max-width: 470px) {
  .headerContainerReserve {
    font-size: 20px;
  }
}

.applyButton {
  background-color: #D3BEA5;
  text-align: center;
  color: black;
  font-size: var(--large-header-size);
  font-weight: var(--header-text-property);
  font-family: var(--header-text-font);
  padding: 20px 20px 20px 20px;
  margin-bottom: 20px;
  border-style: solid;
  border-color: black;
  border-radius: 20px;
}

.instructionContainer {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 20px;
  justify-content: center;
  text-align: center;
  margin-top: 3vw;
  margin-bottom: 3vw;
  padding-left: 10%;
  padding-right: 10%;
  color: white;
  font-family: var(--generic-website-text);
  font-size: var(--generic-text-size);
}

@media only screen and (max-width: 930px) {
  .instructionContainer {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    width: 80%;
  }
}

.individualContainer {
  font-size: var(--header-font-size);
  background-color: #4E3A24;
  border-radius: 10px;
  padding: 10px;
}

@media only screen and (max-width: 690px) {
  .individualContainer {
    font-size: 16px;
  }
}

.additionalInfoContainer {
  width: 90vw;
  margin: 10px 10px 10px 10px;
  background-color: #4E3A24;
  color: white;
  font-family: var(--generic-website-text);
  font-size: var(--generic-text-size);
  padding: 5px 10px 10px 10px;
  text-align: center;
  margin-bottom: 5vw;
  border-style:inset;
  border-width: 5px;
  border-radius: 20px;
  border-color: #e2b78e;
}

/*.additionalInfoContainer h3 {
  /*font-size: 40px;
  /*font-weight: bold;
  padding-left: 10px;
}

.additionalInfoContainer p {
  /*font-size: 28px;
  font-size: var(--header-font-size);
}*/

.title {
  font-family: var(--header-text-font);
  font-size: var(--large-header-size);
  text-align: center;
  margin-bottom: 1vw;
}

/* Email Form for Reserving A Kitten */

.formContainer {
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  width: 80%;
  font-size: 1rem;
  background: #4E3A24;
  color: white;
  border-radius: 15px;
}

.formTextInputs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 2;
}

.formKittenSelect {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  flex-wrap: wrap;
}

#userName, #userEmail {
  width: 100%;
  height: 2.5rem;
  padding: 1rem;
  outline: none;
  border-radius: 5px;
  border: 1px solid rgb(220, 220, 220);
}

#userMessage {
  width: 100%;
  height: 9rem;
  padding: 1rem;
  outline: none;
  border-radius: 5px;
  border: 1px solid rgb(220, 220, 220);
}

#userName:focus, #userEmail:focus, #userMessage:focus {
  border: 2px solid rgba(168, 125, 72, 0.96);
}

#formLabel {
  font-size: 1.5rem;
  margin-top: 1rem;
}

#submitButton {
  width: 20%;
  border-radius: 5px;
  border: 1px solid rgb(220, 220, 220);
  align-self: flex-end;
  margin-right: 7rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
  cursor: pointer;
  background: white;
  color: rgb(168, 125, 72);
  box-shadow: 0px 0px 2px 2px rgb(0,0,0);
  border: none;
  font-size: 1.7rem;
  font-weight: bold;
}

#submitButton:hover {
  background: rgb(168, 125, 72);
  color: white;
  transition: 0.7s;
}

@media only screen and (max-width: 800px) {
  
  .instructionContainer {
    flex-direction: column;
  }

  
}