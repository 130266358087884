@import "../../theme.css";


.contactUsPage {
  background-color: #a87d48f5;
}

.bg {
  background-image: var(--bg-image2);
  background-attachment: fixed;
  background-size: 100%;
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.mainContentContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  background-color: var(--light-primary-color);
  color: black;
  margin-bottom: 50px;
  width: 100%;
  height: 100%;
  margin-top: 0%;
  font-family: var(--generic-website-text);
  font-size: var(--generic-text-size);
  gap: 1rem;
  border-style: solid;
  border-color: var(--dark-primary-color);
  border-width: 10px 0px 10px 0px
}

.mainContentContainer h1 {
  font-size: var(--large-header-font-size);
  font-weight: bolder;
}

.mainContentContainer h2 {
  font-size: var(--header-font-size);
  font-weight: bolder;
}

.mainContentContainer p {
  font-size: var(--header-font-size);
  font-weight: bolder;
}

.formContainer {
  margin-top: -4rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 80%;
  font-size: 1rem;
}

#userName, #userEmail {
  width: 100%;
  height: 4.5rem;
  padding: 1rem;
  outline: none;
  border-radius: 5px;
  border: 1px solid rgb(220, 220, 220);
}

#userMessage {
  width: 100%;
  height: 9rem;
  padding: 1rem;
  outline: none;
  border-radius: 5px;
  border: 1px solid rgb(220, 220, 220);
}

#userName:focus, #userEmail:focus, #userMessage:focus {
  border: 2px solid rgba(168, 125, 72, 0.96);
}

#formLabel {
  font-size: 1.5rem;
  margin-top: 1rem;
}

#submitButton {
  width: 20%;
  border-radius: 5px;
  border: 1px solid rgb(220, 220, 220);
  align-self: center;
  margin-top: 2rem;
  margin-bottom: 1rem;
  cursor: pointer;
  background: white;
  color: rgb(168, 125, 72);
  box-shadow: 0px 0px 2px 2px rgb(0,0,0);
  border: none;
  font-size: 1.7rem;
  font-weight: bold;
}

#submitButton:hover {
  background: rgb(168, 125, 72);
  color: white;
  transition: 0.7s;
}

.bottomContainter{
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
}