@import "../../theme.css";

.parentContainer {
  width: 100%;
  display: block;
  background-color: var(--dark-secondary-color);
  color: white;
  align-content: center;
  font-family: var(--generic-website-text);
  font-size: var(--generic-text-size);
  padding: 1vw;
  border-style: double;
  border-color: var(--dark-primary-color);
  border-width: 10px;
  border-radius: 20px;
}

.parentContainer h4 {
  text-align: center;
}
.parentContainer p {
  text-align: center;
}

.parentAndSlider {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.parentImg {
  flex: 1;
  min-width: 150px;
  /* max-height: 300px; */
  overflow: hidden;
}
.parentImg img {
  height: 100%;
  object-fit: cover;
  align-self: center;
  border-style: double;
  border-color: var(--dark-primary-color);
  border-width: 10px;
  border-radius: 20px;
}
@keyframes slideOpen {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(50%);
  }
}

.sliderInfo {
  flex: 1;
  animation-name: slideOpen;
  animation-duration: 0.5s;
  width: 100%;
  color: black;
  min-width: max-content;
  height: min-content;

  padding: 2vw 1vw;
  font-size: var(--generic-text-size); /*1.1rem;*/
  background-color: var(--light-secondary-color); /* #fff;*/
  border-color: var(--light-primary-color);
  border-width: 10px 0px 5px 0px;
  border-radius: 1rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.sliderInfo tr {
  height: 2.5vw;
  box-sizing: border-box;
}
.sliderInfo th {
  width: 35%;
  min-width: 5rem;
  margin-top: 1rem;
}

.sliderInfo td {
  width: 65%;
  text-overflow: ellipsis;
}
