@import "../../theme.css";

.litterUpdatesPage {
  background-image: var(--bg-image2);
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
}

.litterInstructionContainer {
  font-family: var(--generic-website-text);
  font-size: var(--generic-website-size);
  /*opacity: 0.95;*/
  padding: 1%;
  display: flex;
  width: 70%;
  margin: auto;
  margin-top: 5vw;
  flex-direction: column;
  color: black;
  background-color: #a87d48f5;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-style: solid;
  border-width: 5px;
  border-radius: 20px;
  border-color: #e2b78e;
}

.parentCardWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 3vw;
  width: 60%;
  margin-left: 20%;
  margin-right: 20%;
  margin-top: 3vw;
  margin-bottom: 3vw;
}

.parentCard {
  background-color: #e2b78e;
  opacity: 0.85;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 0.1fr 1fr;
  gap: 1vw;
  width: 100%;
  height: fit-content;
  overflow: hidden;
  align-items: center;
  padding: 10px;
  border-style: solid;
  border-radius: 20px;
  border-width: 5px;
  border-color: #a87d48f5;
}

.parentName{
  text-align: center;
  font-family: var(--header-text-font);
  font-size: 30px;
  color: black;
}

.parentImage {
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  max-width: 100%;
  max-height: 90%;
  border-style: solid;
  border-radius: 20px;
  border-width: 3px;
  border-color: #a87d48f5;
}

.parentImage img {
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
}

.litterWrapper{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 3vw;
  width: 60%;
  height: fit-content;
  margin-left: 20%;
  margin-top: 5vw;
  margin-bottom: 10vw;
}

.litterInfo {
  font-family: var(--generic-website-text);
  font-size: var(--generic-website-size);
  color: black;
  background-color: #a87d48f5;
  text-align: left;
  padding: 5px;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  border-style: solid;
  border-width: 5px;
  border-color: #e2b78e;
  border-radius: 10px;
}

.litterInfo h1{
  font-size: var(--header-font-size);
  line-height: 1;
  margin-top: -2px;
}

.litterInfo p{
  font-size: var(--generic-website-size);
  line-height: 1;
}

.litterInfo img{
  max-height: 85%;
  min-height: 80%;
  max-width: 100%;
  min-width: 70%;
  margin: .1vw;
  border-radius: 20px;
}

@media only screen and (max-width: 940px) {

  .parentCardWrapper {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }

  .litterWrapper {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    width: 60%;
    margin-left: 20%;
  }

}
