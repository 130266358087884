@import "../../theme.css";

.footerContainer {
  width: 100%;
  margin: auto;
  background-color: #412804;
  padding: 1rem 0.3rem;
  border-top: #e2b78e outset 0.75rem;
  border-bottom: #41280439 outset 0.85rem;
  display: grid;
  gap: 0.5rem;
  color: #e2b78e;
  font-family: inika, lancelot;
  font-size: small;
  text-align: left;
}

@media screen {
  .footerContainer {
    grid-template-columns: repeat(3, 1fr);
  }
}

.footerContainer section {
  text-align: center;
  max-width: max-content;
}
.footerContainer hr {
  height: 0.25rem;
}

.footerContainer h4 {
  font-size: 1.5rem;
  font-weight: bold;
  height: 0.5rem;
}

.footerLinks:hover {
  color: aqua;
  cursor: pointer;
}

.followUSContainer {
  width: 90%;
  margin: auto;
  margin-top: 4vw;
  margin-left: 3vw;
}
.followUSContainer a {
  color: #e2b78e;
  font-size: medium;
}

.instagramBtn {
  border-width: 0px;
  background-color: #41280400;
  cursor: progress;
  width: 50px;
}

.instagramImg {
  width: 100%;
  border-radius: 1rem;
  background-color: #41280400;
  border-radius: 25%;
  border-style: outset;
  border-width: 0.25rem;
  border-color: #e2b78e;
  /* padding: 0.05rem; */
}

.logoContainer{
  width: 15vw;
  height: auto;
  background-color: transparent;
  align-content: center;
  margin-left: 8.5vw;
  padding-bottom: 1vw;
}

.contactUsContainer {
  width: 90%;
  margin: auto;
  margin-top: 4vw;
  margin-right: 3vw;
}
.contactUsContainer li {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}
