@import "../../theme.css";

.ourCatsPage {
  background-image: var(--bg-image2);
  background-size: cover;
  background-attachment: fixed;
  height: 100%;
}
.ourCatsHeader {
  width: 60%;
  margin: 3rem auto;
}
.ourCatsHeader img {
  margin-top: 3vw;
  margin-bottom: 3vw;
  border-color: var(--light-primary-color);
  border-width: var(--border-width);
  border-radius: var(--border-radius);
  border-style: inset;
  border-width: 5px;
  border-radius: 20px;
  border-color: #f0c398;
}

.missionStatement {
  width: 90%;
  max-width: 1000px;
  margin: 3rem auto;
  height: auto;
  position: relative;
  padding: 2rem;
  background-color: rgb(184, 144, 96, 0.95);
  color: rgb(255, 255, 255);
  border-width: 0px 0px 5px 0px;
  border-bottom: #e2b78e;
  border-style: solid;
  border-style: solid;
  border-width: 5px;
  border-radius: 20px;
  border-color: #e2b78e;
  border-radius: var(--border-radius);
  border-width: var(--border-width);
  border-color: var(--light-primary-color);
  bottom: 0.2vw;
  left: 0.2vw;
}
.missionStatementText {
  font-family: var(--generic-website-text);
  font-size: var(--generic-text-size);
  width: 90%;
  margin: auto;
  text-align: center;
}

.headerBackground {
  background-image: url("../../images/OurCatsHeader.png");
  background-size: cover;
  background-position: center center;
  /* height: 850px; */
  border-style: solid;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-color: black;
}

.headerContainerOurCats {
  text-align: center;
  color: white;
  font-family: var(--header-text-font);
  font-size: var(--large-header-size);
  font-weight: var(--header-text-property);
  top: 150px;
  width: 100%;
  height: fit-content;
  vertical-align: text-top;
  padding-top: 50px;
  padding-left: 25%;
  padding-right: 25%;
}

.body {
  background-color: rgb(184, 144, 96, 0.95);
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  border-style: solid;
  border-color: var(--light-primary-color); /*#6b462a;*/
  border-width: 5px;
  border-radius: 20px;
  margin: auto;
  padding: 3rem;
  margin-top: 3vw;
  margin-bottom: 4vw;
  width: 95%;
  max-width: 1100px;
  gap: 3rem;
}

.organizations {
  flex: 1.5;
  min-width: 350px;
  /* margin: auto; */
  align-self: flex-start;
}

.organizationText {
  color: black;
  font-family: var(--generic-website-text);
  font-size: var(--header-font-size);
  line-height: 1.1;
  margin: auto;
}

.ticaBtn {
  width: auto; /*60%;*/
  height: auto; /*18%;*/
  margin-left: 25%;
  margin-right: 25%;
  background-color: white;
  border-style: solid;
  border-color: var(--dark-secondary-color);
  border-radius: 10px;
  border-width: 5px;
  cursor: pointer;
}

.ticaLogo {
  width: 100%;
  height: 100%;
}

.parentColumn {
  flex: 2;
  min-width: 350px;
}
