@font-face {
  font-family: inika;
  src: url("./fonts/Inika-Regular.ttf");
}
@font-face {
  font-family: lancelot;
  src: url("./fonts/Lancelot-Regular.ttf");
}

:root {
  --bg-image1: url("./images/kbengalsbgGradient.png");
  --bg-image2: url("./images/bengal-cat-coat-glitter.jpg");
  --light-primary-color: #e2b78e;
  --light-secondary-color: #ffe3c8;
  --dark-primary-color: #a87d48f5;
  --dark-secondary-color: #4E3A24;
  /* --border-radius: 20px;
  --border-width: 3px; */
  --font-inika: inika;
  --font-lancelot: lancelot;
  --header-text-font: inika;
  --header-font-size: 20px;
  --largest-header-size: 36px;
  --large-header-size: 24px;
  --header-text-property: bold;
  --generic-website-text: inika;
  --generic-text-size: 16px;
  --generic-text-property: bold;
  --dark-bg-opacity: 0.9;
  --light-bg-opacity: 0.85;
  --border-radius: 10px;
  --border-width: 5px;
  --font-width: auto;

}