@import "../../theme.css";

/***************************** Owner's Info header ********************************/

/*.ownersInfoPage {
  position: absolute;
  height: 100%;
  width: 100%;
}*/

.backgroundLayer {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  /*background-position:0%;*/
  /*background-repeat:round;*/
  background-attachment: fixed;
  background-color: #a87d48f5; /*border color: #e2b78e;*/
  background-image: var(--bg-image2);
  background-size: cover;
}

.headerBackground {
  /*background-image: url("../../images/bencat - OwnersInfo header image.jpg");*/
  background-size: cover;
  background-position: center center;
}

.backgroundLayer Img {
  max-width: 100%;
  height: auto;
}

.headerOwnersInfo {
  width: auto;
  position: absolute;
  /*bottom: 1vw;*/
  text-align: left;
  top: 35vw;
  margin-left: 5vw;
  /*font-family: var(--generic-website-text);
  font-size: var(--large-header-size);
  font-weight: var(--header-text-property);*/
  color:#4E3A24;
}

.boldHeader{
  font: bold;
  font-size: 5vw;
  font-family: inika;
  /*font: var(--header-text-property);*/
  /*font-size: var(---large-header-size);
  font-family: var(--generic-website-text);*/
  width: auto;
  position: relative;
  justify-content: center;
}

/***************************** Products we Recommend ********************************/

.text {
  width: auto;
  position: relative;
  justify-content: center;
  font-size: var(--header-font-size);
  font-family: var(--generic-website-text);
  /*font-size: 1vw;
  font-family: inika;*/
  /*font: var(--header-text-property);
  font-family: var(--generic-website-text);
  font-size: var(--generic-text-size);*/
}

.bold{
  font: bold;
  /*font-size: 3vw;
  font-family: inika;*/
  /*font: var(--header-text-property);
  /*font-size: var(---large-header-size);*/
  font-family: var(--generic-website-text);
  width: auto;
  position: relative;
  justify-content: center;
}

.boldSub{
  font: bold;
  /*font-size: 2vw;
  font-family: inika;*/
  /*font-size: var(---large-header-size);*/
  font-family: var(--generic-website-text);
  width: auto;
  position: relative;
  justify-content: center;
}

.underline{
  text-decoration: underline;
  /*font: italic;*/
  /*font: bold;*/
}

.containerFullWidth {
  display: flex;
  flex-direction: column;
  /*position:relative;*/
  width: 100vw;
  height: auto;
  justify-content: center;
  text-align: center;
  /*right: 1rem;*/
  background-color: var(--light-primary-color);
  color: black; /*white;*/
  font-family: var(--generic-website-text);
  font-size: var(--generic-text-size);
  margin-bottom: 60px;
  /*border-style:inset;
  border-color: rgba(255, 230, 207, 0.94);
  border-width: 20px;
  border-radius: 40px;*/
}

.containerRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: center;
  text-align: center;
  width: relative;
  position: relative;
  /*right: 1rem;*/
  background-color:var(--light-primary-color);
  margin-top: 1vw;
  margin-bottom: 3vw;
}

.imgArrow {
  width: 100%;
  height: auto;
  position: relative;
  margin-left: 5vw;
  margin-right: 5vw;
  /*margin: 20vw;*/
}

.button {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--dark-secondary-color);
  color: white;
  cursor: pointer;
  justify-content: center;
  margin-top: 2vw;
  padding-left: 1vw;
  padding-right: 1vw;
  padding-top: 1vw;
  padding-bottom: 0.5vw;
  border-style: double;
  border-color: var(--dark-primary-color);
  border-width: 10px;
  border-radius: 20px;
}

.imgArrow:hover {
  animation-name: rotate;
  animation-duration: 0.7s;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.containerBox {               /*WebNaveHeader color: #412804*/
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  width: relative; 
  max-width: 90vw;
  position: relative;
  background-color: var(--dark-secondary-color);/*, 0.99); /*#4E3A24;*/
  color: white;
  font-family: var(--generic-website-text);
  font-size: var(--generic-text-size);
  margin-top: 2vw;
  margin-bottom: 60px;
  padding: 10px 10px 10px 10px;
  border-style: solid;
  border-color: var(--dark-primary-color);
  border-width: 10px;
  border-radius: 20px;
}

.txtKittenDev {
  text-align: right;
  right: 1rem;
}


/************************** Kitten Development Stages *****************************************/

.imgContainer {
  max-width: 100%;
  height: auto;
  border-style: double;
  border-color: var(--dark-primary-color);
  border-width: 7px;
  border-radius: 20px;
  position: relative;
}

/******************************** Transparent Row container *************************************/

.containerRowTransparent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  width: 80vw;
  position: relative;
  /*right: 1rem;*/
  background-color: var(--dark-primary-color);/*transparent;*/
  color: white;
  font-family: var(--generic-website-text);
  font-size: var(--generic-text-size);
  margin-top: 2vw;
  margin-left: 5vw;
  margin-right: 5vw;
  margin-bottom: 60px;
  border-style: double;
  border-color: var(--dark-secondary-color);
  border-width: 10px;
  border-radius: 20px;
}
 

/************************** Prepare For Kitten *****************************************/

.containerRowBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  width: 80vw;
  position: relative;
  /*right: 1rem;*/
  background-color: var(--light-primary-color);
  color: white;
  font-family: var(--generic-website-text);
  font-size: var(--generic-text-size);
  margin-top: 2vw;
  margin-left: 5vw;
  margin-right: 5vw;
  margin-bottom: 60px;
}

.containerRow1 {
  margin: 10px 10px 10px 10px;
  background-color: var(--dark-primary-color);/*#4E3A24;*/
  flex: 0.9;
  width: 45vw;
  align-items: center;
  align-content: center;
  /*height: auto;*/
  position: relative;
  border-radius: 10px;
  /*justify-content: center;
  border-width: 20px 20px 20px 20px;*/
}

.containerRow2 {
  margin: 10px 10px 10px 10px;
  background-color: var(--light-secondary-color);
  flex: 1;
  /*display: flex;
  flex-direction: row;*/
  width: 30vw;
  height:auto;
  position: relative;
  justify-content: center;
  padding: 15px 15px 15px 15px;
  text-align: center;
  color: black;
  border-radius: 20px;
  border-style: solid;
  border-color: var(--dark-secondary-color);
  border-width: 10px;
}

.imgContainer2 {
  display: flex;
  margin-top: 2vw;
  margin-bottom: auto;
  align-items: center;
  justify-content: center;
  /*background-image: url("../images/bengal-cat.jpg");*/
  /*background-size: contain;*/
  border-style: double;
  border-color: var(--dark-secondary-color);
  border-radius: 20px;
  border-width: 10px;
}

/******************************* Media screen when window size shrinks *******************************************/

@media only screen and (max-width: 1200px) {

  .containerRowBox {
    flex-direction: column;
    align-content: center;
    align-items: center;
    background-color: var(--light-primary-color);
  }

  .containerRow1 {
    width: 78vw;
    background-color: var(--dark-secondary-color);
  }

  .containerRow2 {
    /*display:contents;*/
    width: 78vw;
    border-style: solid;
    border-color: var(--dark-secondary-color);
    border-radius: 20px;
    border-width: 10px;
    /*flex-direction: row;*/
    /*width: 80vw;*/
  }

  .imgContainer {
    width: 28vw;
    margin-left: 30%;
  }

  .imgContainer2 {
    margin-top: 0vw;
    margin-bottom: 0vw;
    width: 80%;
    margin-left: 8vw;
  }

  .containerRowTransparent {
    flex-direction: column;
    width:fit-content;
    background-color: var(--dark-primary-color);
    border-style: double;
    border-color: var(--dark-secondary-color);
    border-radius: 20px;
    border-width: 10px;
  }

  /*.bold {
    font-size: 2.5vw;
  }

  .boldSub {
    font-size: 1vw;
  }

  .text {
    font-size: 1.3vw;
    width: max-content;
  }*/
  /*tr {
    display: inline-flex;
    flex-direction: column;
  }*/
}

/****************************** Medical Info / Vet Stuff ***************************************************/

.imgCenter {
  justify-content: center;
  max-width: 100%;
  height: auto;
}

/****************************** Training ***************************************************/


/****************************** Pet Insurance ***************************************************/
.containerRowBox2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  width: 70vw;
  /*right: 1rem;*/
  background-color: var(--dark-primary-color);
  color: white;
  font-family: var(--generic-website-text);
  font-size: var(--generic-text-size);
  margin-top: 1rem;
  margin-left: 5rem;
  margin-right: 5rem;
  margin-bottom: 60px;
}

.containerRow3 {
  margin: 10px 10px 10px 10px;
  background-color: #4E3A24;
  flex: 1;
  width: 45vw;
  /*height: auto;*/
  position: relative;
  border-radius: 10px;
  /*justify-content: center;
  border-width: 20px 20px 20px 20px;*/
}

/****************************** Social Media ***************************************************/

.textLeftBold {
  font: bold;
  text-align: left;
  margin-left: 1rem;
  width: 30vw;
}

.containerBottom {
  display: flex;
  flex-direction: row;
  width: 100vw;
  justify-content: left;
  text-align: left;
  /*right: 1rem;*/
  background-color:rgba(78, 58, 36, 0.5);
  color: white;
  font-family: inika;
  margin-top: 5vw;
  /*margin-left: 0;
  margin-right: 0;
  /*margin-bottom: 60px;*/
}
