/* *********************   Add new Litter   *********************/
.newLitterContainer {
  width: 90%;
  margin: auto;
  margin-top: 2vw;
  padding: 2rem;
  background-color: rgba(38, 43, 1, 0.497);
  border-radius: 1rem;
  border-color: rgb(243, 211, 49);
  border-style: solid;
  border-width: 0.5rem;
  display: block;
  color: rgb(254, 254, 254);
}
.newLitterContainer label {
  min-width: 8rem;
  padding: 0.25rem 1rem;
  margin: 0.5rem 0rem;
  border-radius: 0.5rem;
  color: black;
  text-align: center;
  background-color: rgba(244, 173, 30, 0.989);
}
.approxGoHome {
  width: 20rem;
}
.newLitterContainer input {
  color: black;
  border-radius: 0.2rem;
  padding: 0.5rem, 1rem;
}
.newLitterContainer button {
  background-color: rgba(28, 94, 2, 0.844);
  border-color: rgb(253, 253, 63);
  border-width: 0.25rem;
  font-size: large;
  font-weight: bold;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
}
.newLitterContainer select {
  color: black;
  border-radius: 0.2rem;
  padding: 0.5rem, 1rem;
  margin: 0.5rem 0rem;
  background-color: rgb(184, 251, 68);
}
