/* ******************   Display each Parents   *********************/
.parentContainer {
  width: 90%;
  margin: auto;
  padding: 2rem;
  background-color: rgba(31, 36, 178, 0.227);
  border-radius: 1rem;
  border-color: rgb(9, 239, 247);
  border-style: solid;
  border-width: 0.5rem;
  display: grid;
  color: rgb(254, 254, 254);
}
@media screen {
  .parentContainer {
    grid-template-columns: repeat(3, 1fr);
  }
}
/* ****************** Delete Option - Parent ******************/
.deleteContainer {
  width: 90%;
  text-align: right;
  margin: auto;
  margin-top: 0rem;
}
.deleteContainer button {
  background-color: rgba(240, 45, 6, 0.976);
  border-color: rgb(235, 242, 37);
  border-width: 0.25rem;
  font-size: 1.85rem;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.965);
  padding: 0.5rem 1rem;
  border-radius: 1rem;
}
/* ********************   Parent Picture   *********************/
.pictureContainer {
  width: 90%;
  margin: auto;
  border-radius: 15%;
}
.pictureContainer img {
  width: 100%;
}

/* ********************** Parent Info *********************/
.parentInfo {
  width: 90%;
  margin: auto;
  margin-top: 0rem;
}
.parentInfo label {
  min-width: 8rem;
  padding: 0.25rem 1rem;
  margin: 0.5rem 0rem;
  border-radius: 0.5rem;
  color: black;
  text-align: center;
  background-color: rgba(247, 191, 36, 0.995);
}
.parentInfo input {
  color: rgb(6, 6, 6);
  background-color: rgba(243, 231, 178, 0.918);
  border-radius: 0.5rem;
  padding: auto, 1rem;
}

/* ****************** Edit Options - Parents ******************/
.edittingOptions {
  width: 90%;
  margin: auto;
  margin-top: 0rem;
}

.edittingOptions label {
  min-width: 8rem;
  padding: 0.25rem 1rem;
  margin: 0.5rem 0rem;
  border-radius: 0.5rem;
  color: black;
  text-align: center;
  background-color: rgba(255, 227, 14, 0.943);
}
.edittingOptions input {
  color: black;
  border-radius: 0.2rem;
  padding: 0.5rem, 1rem;
}
.edittingOptions button {
  background-color: rgba(246, 137, 28, 0.988);
  border-color: rgba(0, 248, 4, 0.93);
  border-width: 0.25rem;
  font-weight: bold;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
}

.edittingOptions legend {
  max-width: fit-content;
  border-style: none;
  font-weight: bold;
  display: flex;
  color: white;
  border-radius: 0.5rem;
  font-size: 1.5rem;
}

.edittingOptions fieldset {
  width: 90%;
  min-width: 8rem;
  margin: 0.5rem 0rem;
  border-width: 0.5rem;
  border-radius: 1rem;
  font-weight: bold;
  padding: 1rem;
  display: flex;
  flex-flow: row wrap;
  background-color: rgba(242, 164, 20, 0.291);
  font-size: 1.5rem;
}
.edittingOptions fieldset label {
  max-width: fit-content;
  border-color: rgb(225, 255, 0);
  background-color: rgb(174, 250, 41);
  color: rgb(77, 38, 2);
  border-width: 0.25rem;
  padding: 0rem;
  font-size: normal;
}
