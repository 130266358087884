@import "../../theme.css";

/* .topSection {
  background-color: blue;

} */

.aboutPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: conic-gradient(
    rgb(64, 29, 2),
    rgb(255, 174, 0),
    rgb(64, 29, 2)
  );
  background-image: var(--bg-image2);
  background-size: cover;
  background-attachment: fixed;
  white-space: pre-line;  
  /*vertical-align: bottom;*/
}

.header{
  background-color: var(--dark-primary-color);
  color: white;
  text-align: center;
  font-family: var(--header-text-font);
  font-size: var(--large-header-size);
  padding-top: 1.25rem;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 3vw;
  /*margin-bottom: 1vw;*/
  /*height: 6.25rem;
  /*outline-style: solid;
  outline-width: 0.0625rem;
  outline-color: black;*/
  border-style: solid;
  border-width: 5px;
  border-radius: 20px;
  border-color: var(--dark-secondary-color); /*#e2b78e;*/
}

.p1{
  background-color: var(--dark-primary-color);
  color: white;
  text-align: left;
  font-family: var(--generic-website-text);
  font-size: var(--generic-text-size);
  padding-top: 0.625rem;
  padding-left: 1.25rem;
  padding-bottom: .625rem;
  /*height: 15.625rem;*/
  width: 60%;
  margin: 0.625rem 0.625rem 0.625rem 0.625rem;
  outline-style: solid;
  outline-width: 0.0625rem;
  outline-color: black;
  /*border-radius: 0.625rem;*/
  border-style: solid;
  border-width: 10px;
  border-radius: 20px;
  border-color: #e2b78e;
}

.box1{
  background-color: var(--light-primary-color);
  align-items: center;
  overflow: hidden;
  /*height: 15.625rem;*/
  width: 18.75rem;
  margin-top: -15.625rem;
  margin-left: 65%;
  margin-right: 1.25rem;
  margin-bottom: 1.875rem;
  outline-style: solid;
  outline-width: 10px;
  outline-color: var(--light-primary-color);
}

.topRightImg img {
  width: 35vw;
  height: auto;
}

.border {
  display:flex;
  flex-direction: column;
  width: 37%;
  height: auto;
  margin: auto;
  align-content: center;
  position: relative;
  border-style: groove;
  /*border-top: groove;
  border-bottom: groove;*/
  border-color: var(--dark-primary-color);
  border-width: 10px;
  border-radius: 20px;
}

.borderTop {
  display:flex;
  flex-direction: column;
  width: 40%;
  height: auto;
  margin: auto;
  align-content: center;
  position: relative;
  border-style: groove;
  border-color: var(--dark-primary-color);
  border-width: 10px;
  border-radius: 20px;
}

.p2{
  background-color: var(--dark-secondary-color);
  color: white;
  text-align: left;
  font-family: var(--generic-website-text);
  font-size: var(--generic-text-size);
  padding-top: 0.625rem;
  padding-left: 1.25rem;
  /*height: 28.125rem;*/
  width: 95%;
  margin: 20px; /* 0.625rem 0.625rem 0.625rem 0.625rem;*/
  margin-bottom: 5vw;
  margin-top: 5vw;
  outline-style: solid;
  outline-width: 0.0625rem;
  outline-color: black;
  /*border-radius: 0.625rem;*/
  border-style: solid;
  border-width: 10px;
  border-radius: 20px;
  border-color: #e2b78e;
}

.box2{
  background-color: var(--light-primary-color);
  align-items: center;
  overflow: hidden;
  /*height: 18.75rem;*/
  width: 21.875rem;
  /*margin-top: 3.125rem;
  margin-left: 20%;
  margin-right: 1.25rem;
  margin-bottom: 1.875rem;*/
  outline-style: solid;
  outline-width: 10px;
  outline-color: var(--light-primary-color);
}

.bottomLeftImg img {
  max-width: 35vw;
  height: auto;
}

.box3{
  background-color: var(--light-primary-color);
  align-items: center;
  overflow: hidden;
  /*height: 18.75rem;*/
  width: 21.875rem;
  /*margin-top: -330px;
  margin-top: -20.5rem;
  margin-left: 60%;
  margin-right: 1.25rem;
  margin-bottom: 1.875rem;*/
  margin-left: 60%;
  outline-style: solid;
  outline-width: 10px;
  outline-color: var(--light-primary-color);
}

.bottomRightImg img {
  max-width: 35vw;
  height: auto;
}

.p3 {
  background-color: var(--light-primary-color);
  color: black; /*white;*/
  width: 80vw;
  text-align: left;
  font-family: var(--generic-website-text);
  font-size: var(--header-font-size);
  padding-top: 1rem;
  padding-left: 1rem;
  margin-bottom: 5vw;
  margin-top: 5vw;
  margin-left: 5vw;
  /*height: 6.25rem;*/
  /*margin-top: 6.25rem;
  margin-left: 30%;
  margin-right: 30%;
  /*margin-bottom: -1.875rem;*/
  outline-style: solid;
  outline-width: 0.0625rem;
  outline-color: black;
  border-radius: 0.625rem;
  border-style: solid;
  border-width: 10px;
  border-radius: 20px;
  border-color: var(--dark-primary-color);
}

.bold {
  font: bold;
  text-align: center;
}

.containerRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  width: 90vw;
  position: relative;
  /*right: 1rem;*/
  background-color:#4E3A24;
  margin-top: 5vw;
  margin-bottom: 5vw;
}

.containerRowStay {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  width: 90vw;
  position: relative;
  /*right: 1rem;*/
  background-color:#4E3A24;
  margin-top: 5vw;
  margin-bottom: 5vw;
}

.containerRow2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: center;
  width: 60vw;
  position: relative;
  /*right: 1rem;*/
  /*background-color:#4E3A24;*/
  margin-left: 15vw;
  margin-top: 1vw;
  margin-bottom: 3vw;
}

/*@media only screen and (max-width: 1200px) {

  .containerRow {
    flex-direction: column;
  }

  .p1 {
    width:fit-content;
  }
}*/

.imgContainer {
  max-width: 40vw;
  height: auto;
  /*background-image: url("../images/bengal-cat.jpg");*/
  /*background-size: contain;*/
  border-style: solid;
  border-color: #a87d48f5;
  /*position: relative;*/
}